import { derived } from 'svelte/store';
import { comicData } from './dataStore';

import {
  forEach,
  map,
  range,
  sumBy,
  values,
  orderBy,
  flatten,
} from 'lodash-es';
import {
  colorSchemes,
  bins,
  binStep,
  createGroupedStats,
  createStacks,
  createConnections,
} from '../utils/colors';

function generateUID() {
  // I generate the UID from two parts here
  // to ensure the random number provide enough bits.
  var firstPart = (Math.random() * 46656) | 0;
  var secondPart = (Math.random() * 46656) | 0;
  firstPart = ('000' + firstPart.toString(36)).slice(-3);
  secondPart = ('000' + secondPart.toString(36)).slice(-3);
  return firstPart + secondPart;
}

/*
 ------------------ C O M I C S ------------------ 
*/

export const comics = derived(
  comicData,
  ($comicData) => prepComics($comicData) || []
);

export const comicList = derived(
  comics,
  ($comics) =>
    map($comics, (d) => {
      let colorSchemeDict = {};
      let maxHeight = 0;

      forEach(colorSchemes, (c, i) => {
        colorSchemeDict[i] = { id: i, name: c, count: 0 };
      });

      forEach(d.pages, (p) => {
        if (p.colorScheme) {
          colorSchemeDict[p.colorScheme.id].count++;
        }
      });

      const groupedColorSchemes = createGroupedStats(d.pages, (x) =>
        x.colorScheme ? x.colorScheme.id : undefined
      );

      return {
        id: d.id,
        shortId: generateUID(),
        name: d.name,
        cover: `covers/${d.id}.jpg`,
        artist: d.artist,
        serie: d.serie,
        year: d.year,
        pageNum: d.pages.length,
        colorBins: map(d.pages, (p) => {
          return {
            id: p.id,
            ...p.colorBins,
            sum: sumBy(values(p.colorBins), 'value'),
          };
        }),
        connections: flatten(createConnections(groupedColorSchemes)),
        stacks: createStacks(groupedColorSchemes),
        colorSchemes: orderBy(values(colorSchemeDict), 'count', 'desc'),
      };
    }) || []
);

export const isReady = derived([comics], ([$comics]) => {
  return $comics.length !== 0;
});

// export const colors = derived([comics], ([$comics]) => {
//   if ($comics.length == 0) {
//     return [];
//   }

//   const colorDict = [];
//   let pageNum = 0;

//   const myRound = (i) => Math.round(i / 5) * 5;

//   forEach($comics, (comic) => {
//     forEach(comic.pages, (page) => {
//       forEach(page.colors, (color) => {
//         const colorId = `${myRound(color[0])}-${myRound(color[1])}-${myRound(
//           color[2]
//         )}`;
//         if (colorDict[colorId] === undefined) {
//           colorDict[colorId] = {
//             id: colorId,
//             color: {
//               h: myRound(color[0]),
//               s: myRound(color[1]),
//               l: myRound(color[2]),
//             },
//             value: 0,
//             pages: 0,
//           };
//         }
//         colorDict[colorId].value += color[3];
//         colorDict[colorId].pages++;
//       });
//       pageNum++;
//     });
//   });

//   const colorValues = map(values(colorDict), (c) => ({
//     ...c,
//     value: c.value / pageNum,
//   }));

//   return colorValues;
// });

/*
 ------------------ H E L P E R S ------------------ 
*/

function getAvgColor(colors) {
  const avgColor = [0, 0, 0];
  let colorCount = 0;

  forEach(colors, (c) => {
    forEach(range(Math.ceil(c[3] * 100)), () => {
      avgColor[0] += c[0];
      avgColor[1] += c[1];
      avgColor[2] += c[2];
      colorCount++;
    });
  });

  avgColor[0] /= colorCount;
  avgColor[1] /= colorCount;
  avgColor[2] /= colorCount;

  return avgColor;
}

function prepComics(books) {
  const binKey = 0;

  return map(books, (book) => {
    let pages = [];
    let maxRatio = 0;

    forEach(book.pages, (page) => {
      maxRatio = Math.max(page.ratio, maxRatio);

      // set bins
      const pageBins = {};
      forEach(bins, (b) => {
        pageBins[b] = {
          id: `${page.name}-${b}`,
          colors: [],
          value: 0,
          avgColor: null,
        };
      });

      forEach(page.colors, (c) => {
        // bins
        const bin = Math.floor(c[binKey] / binStep) * binStep;
        pageBins[bin].colors.push(c);
        pageBins[bin].value += c[3];
      });

      // sums and avg colors
      forEach(bins, (b) => {
        pageBins[b].value = pageBins[b].value !== 0 ? pageBins[b].value / 1 : 0;
        if (pageBins[b].colors.length) {
          pageBins[b].avgColor = getAvgColor(pageBins[b].colors);
        }
      });

      // create objects

      pages.push({
        id: page.name,
        file: book.id + '/' + page.name + '.jpg',
        dimensions: {
          ratio: page.ratio,
        },

        colors: page.colors,
        colorBins: pageBins,
        colorScheme: page.sc
          ? {
              id: page.sc[0].toString(),
              name: colorSchemes[page.sc[0]],
              bins: page.sc[1],
            }
          : null,
      });
    });

    return {
      ...book,
      ratio: maxRatio,
      pages,
    };
  });
}
