<script>
  import { onMount } from 'svelte';
  import { map, groupBy, orderBy } from 'lodash-es';

  import { comicList } from '../data/dataAPI';
  import { scrollPos } from '../state/uiState.js';

  import Tabs from '../components/ui/Tabs.svelte';
  import Wrapper from '../components/ui/Wrapper.svelte';
  import SubHeader from '../components/ui/SubHeader.svelte';
  import Thumbnail from '../components/vis/Thumbnail.svelte';
  import shortOut from 'lodash-es/_shortOut';
  // import Colors from '../components/vis/Colors.svelte';

  const orderOptions = [
    { id: 'serie', label: 'Serie & Year' },
    { id: 'name', label: 'Title', direction: 'asc' },
    { id: 'year', label: 'Year', direction: 'asc' },
    { id: 'pageNum', label: 'Pages', direction: 'desc' },
  ];

  let orderComicsBy = 'serie';

  //  remove no scroll class
  if (window.document.body.classList.contains('no-scroll')) {
    window.document.body.classList.remove('no-scroll');
  }

  $: currentOrderOptions =
    orderOptions.filter((x) => x.id == orderComicsBy)[0] || orderOptions[0];

  $: orderedComics =
    orderComicsBy === 'serie'
      ? orderBy(
          map(groupBy($comicList, 'serie'), (comics, serie) => {
            comics = orderBy(comics, (d) =>
              Array.isArray(d.year) ? d.year[0] : d.year
            );
            return {
              id: serie,
              serie: serie !== '' ? serie : 'Other Comics',
              years: Array.isArray(comics[0].year)
                ? comics[0].year[0]
                : comics[0].year,
              firstYear: comics[0].year,
              comicCount: comics.length,
              isSerie: serie !== '',
              comics,
            };
          }),
          ['isSerie', 'comicCount', 'firstYear'],
          ['desc', 'desc', 'asc']
        )
      : orderBy($comicList, orderComicsBy, currentOrderOptions.direction);

  onMount(() => {
    if ($scrollPos !== 0) {
      document.documentElement.scrollTop = document.body.scrollTop = $scrollPos;
    }
  });
</script>

<style lang="scss">
  @import './styles/theme.scss';

  .header {
    position: relative;
    width: 100%;
    min-height: 90vh;
    background: url(../images/header.jpg) no-repeat center bottom;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    @include fluid-space(margin-bottom, $sp-medium);
  }

  .header__title {
    position: absolute;
    bottom: 5%;

    @media #{$m-min-sm} {
      width: 80%;
    }
    @media #{$m-min-md} {
      width: 70%;
    }

    h1 {
      @include fluid-font-size($fs-xxlarge);
      font-weight: $fw-light;
      line-height: 1.1;
    }
  }
  .controls {
    @include fluid-space(margin-bottom, $sp-large);
  }
  .controls__color-schemes {
    @include fluid-space(margin-bottom, $sp-semi-medium);
  }
  .introduction {
    @include fluid-space(margin-bottom, $sp-large);
    p {
      margin-bottom: 1em;
      color: $c-blue-gray-6;
    }

    a {
      color: $c-white;
    }

    h2 {
      @include fluid-font-size($sp-semi-medium);
    }

    .introduction__image {
      @media #{$m-min-sm} {
        width: 70%;
        margin: 0 15%;
      }
      @media #{$m-min-md} {
        width: 50%;
        margin: 0;
        align-self: center;
      }
    }

    .introduction__text {
      @include fluid-space(margin-top, $sp-large);
      @media #{$m-min-md} {
        margin-top: 0;
      }
    }

    @media #{$m-min-md} {
      display: flex;
      flex-direction: row;
      .introduction__image,
      .introduction__text {
        width: 50%;
        box-sizing: border-box;
        padding: 20px 40px;
      }
    }
  }

  .comics {
    color: $c-white;
    width: 100%;
    @include fluid-space(margin-bottom, $sp-large);

    h2 {
      @include fluid-font-size($fs-large);
      @include fluid-space(margin-bottom, $sp-base);
      font-weight: $fw-light;
      text-align: center;
    }
    a {
      color: $c-white;
    }
  }
  .artist-comics,
  .ordered-comics {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    @include fluid-space(margin-bottom, $sp-medium);
  }
  .comic {
    display: inline-box;
    box-sizing: border-box;
    padding: 10px;
    margin: 0 10px 10px 0;
    border: 1px solid $c-blue-gray-3;
  }
  .about {
    @include fluid-space(margin-bottom, $sp-xxlarge);
    p {
      margin-bottom: 1em;
      color: $c-blue-gray-6;
    }

    a,
    strong {
      color: $c-white;
    }

    h2 {
      @include fluid-font-size($sp-large);
      @media #{$m-min-md} {
        @include fluid-space(margin-bottom, $sp-medium);
      }
    }
    h3 {
      @include fluid-font-size($sp-semi-medium);
      @include fluid-space(margin-bottom, $sp-xsmall);
      @include fluid-space(margin-top, $sp-medium);
    }

    @media #{$m-min-md} {
      h3 {
        margin-top: 0;
      }
      .about__items {
        display: flex;
        flex-direction: row;
        .about__item {
          width: 50%;
          box-sizing: border-box;
          &:first-child {
            padding-right: 40px;
          }
          &:last-child {
            padding-left: 40px;
          }
        }
      }
    }
  }
  // .colors__wrapper {
  //   display: flex;
  //   height: 100vh;
  //   width: 100%;
  //   // background-color: $c-white;
  //   @include fluid-space(margin-bottom, $sp-xlarge);
  // }
  .about__header {
    width: 100%;
    text-align: center;
  }
  .preloading {
    display: flex;
    height: 60vh;
  }
</style>

<svelte:body style="overflow-y: auto;" />

<header class="header">
  <Wrapper>
    <div class="header__title">
      <SubHeader>colortheft.com</SubHeader>
      <h1>The Colors of Jean Giraud aka Mœbius</h1>
    </div>
  </Wrapper>
</header>
<Wrapper>
  <div class="introduction">
    <div class="introduction__image">
      <SubHeader isCentered hasSmallMargin>How to read the chart</SubHeader>
      <img src="images/comic.svg" alt="How to read the chart" />
    </div>
    <div class="introduction__text">
      <h2>Mœbius Colors</h2>
      <p>
        by
        <a href="https://christianlaesser.com/" target="_blank">
          Christian Laesser
        </a>
      </p>
      <p>
        Jean Giraud (8 May 1938 – 10 March 2012), aka Mœbius, was a French
        artist, cartoonist, and writer. His most famous works include the series
        Blueberry, created with writer Jean-Michel Charlier, featuring one of
        the first anti-heroes in Western comics. As Mœbius he created a wide
        range of science fiction and fantasy comics in a highly imaginative,
        surreal, almost abstract style (Source:
        <a
          href="https://en.wikipedia.org/wiki/Jean_Giraud#As_Jean_Gir[aud])"
          target="_blank">Wikipedia</a>). His way of working with colors
        intrigued me a lot. So I hope this data visualization lets you explore
        his world. With that said, please read his comic to enjoy the incredible
        detail of his work.
      </p>
      <p>
        The overview represents each comic by a circular thumbnail. Theses
        thumbnails show how the average color of each page changes over time. By
        clicking one of the thumbnails, you reach the detail view. This view
        lets you explore each comic individually. You can zoom from a page to a
        panel level. You pick average colors as well as explore their underlying
        color schemes.
      </p>
      <p>
        <a href="#about">Click here</a>
        to get more information about the data source, copyrights, and used
        technologies.
      </p>
    </div>
  </div>
  <div class="controls">
    <div class="controls__sorting">
      <SubHeader isCentered hasSmallMargin>Group &amp; order by</SubHeader>
      <Tabs options={orderOptions} bind:selected={orderComicsBy} />
    </div>
  </div>
</Wrapper>

<Wrapper isFullWidth>
  <div class="comics">
    {#if orderComicsBy === 'serie'}
      {#each orderedComics as { id, serie, comics } (id)}
        <h2>{serie}</h2>
        <div class="artist-comics">
          {#each comics as { id, shortId, name, year, colorBins, stacks, connections, colorSchemes, pageNum, cover } (id)}
            <Thumbnail
              {id}
              {shortId}
              {name}
              {year}
              {colorBins}
              {colorSchemes}
              {stacks}
              {connections}
              {pageNum}
              {cover} />
          {/each}
        </div>
      {/each}
    {:else}
      <div class="ordered-comics">
        {#each orderedComics as { id, name, artist, year, colorBins, stacks, connections, colorSchemes, pageNum, cover } (id)}
          <Thumbnail
            {id}
            shortId
            {name}
            {year}
            {colorBins}
            {colorSchemes}
            {stacks}
            {connections}
            {pageNum}
            {cover} />
        {/each}
      </div>
    {/if}
  </div>
</Wrapper>
<!-- 
<div class="colors__wrapper">
  <Colors colors={$colors} />
</div> -->
<Wrapper>
  <div class="about" id="about">
    <div class="about__header">
      <SubHeader>Information</SubHeader>
      <h2>About the project</h2>
    </div>
    <div class="about__items">
      <div class="about__item">
        <h3>Sources and Copyright</h3>
        <p>
          All the comics were downloaded from online archives with the research
          purpose to learn more about the cultural phenomenon of comics. As the
          creator of this visualization, I don't hold any copyrights of comics.
          I tried to create the project under the limitations of
          <strong>fair use</strong>. Here some references:
          "<a
            href="https://www.rivaliq.com/blog/guide-copyright-fair-use-laws-online-images/"
            target="_blank">A Guide to Online Images Copyright and Fair Use Laws</a>" and
          "<a
            href="https://garson-law.com/thumbnail-images-infringement-or-fair-use/"
            target="_blank">Thumbnail Images – Infringement or Fair Use?</a>". All the copyright material were only used to extract the color
          information and as small thumbnail images to give context to the
          extracted data.
        </p>
        <p>
          If you are the copyright holder and don't want to have your material
          covered here, please write me an
          <a href="mailto:mail@christianlaesser.com">email</a>.
        </p>
      </div>
      <div class="about__item">
        <h3>Technology</h3>
        <p>
          The preprocessing of the images is done in Python. The color
          extraction is done with the help of
          <a href="https://pypi.org/project/colorgram.py/" target="_blank">
            Colorgram</a>.
        </p>
        <p>
          The visualization is custom-made in HTML, CSS, and JavaScript with
          open-source projects like
          <a href="https://svelte.dev/" target="_blank">Svelte.js</a>
          and <a href="https://d3js.org/" target="_blank">D3.js</a>.
        </p>
        <h3>Credits</h3>
        <p>
          The project was created by
          <a href="https://christianlaesser.com/" target="_blank">Christian Laesser</a>, a independent interaction and data visualization designer based in
          Berlin. </p>
          <p>Thanks to <a href="https://twitter.com/moritz_stefaner" target="_blank">Moritz Stefaner</a>, <a href="https://twitter.com/steffenhaensch" target="_blank">Steffen Hänsch</a> and <a href="https://twitter.com/paulpunkt" target="_blank">Paul Heinicker</a> for their helpful feedback.
          <p>The used font is <a href="https://gt-cinetype.com/" target="_blank">GT-Cinetype</a> by Grilli Type.
        </p>
      </div>
    </div>
  </div>
</Wrapper>
