<script>
  import { forEach, map, sumBy, values, filter, orderBy } from 'lodash-es';

  import {
    bins,
    createGroupedStats,
    createStacks,
    createConnections,
    hslToRGB,
    rgbToHex,
    contrast,
  } from '../../utils/colors.js';

  import PageColorBumpChart from './PageColorBumpChart.svelte';
  import ColorCircle from './ColorCircle.svelte';
  import ColorScheme from './ColorScheme.svelte';
  import Dot from './Dot.svelte';

  export let data = {};
  export let height = 0;
  export let width = 0;
  export let pos = 0;
  export let zoom = 0;

  export let availableWidth = 0;
  export let fullWidth = 0;
  export let hoveredColor = null;
  export let hoveredColorScheme = null;
  export let hoveredCopyColor = null;

  let imagesAreLoaded = false;

  let elements = [];
  let elementStacks = [];
  let elementConnections = [];
  let currentStripeWidth = 0;
  let currentStripeSpace = 0;
  let currentStripeOpacity = 1;
  let colorBinIndex = {};
  let hoveredElement = null;
  let selectedColorScheme = null;

  const zoomStep = 1;
  const margin = { top: 30, right: 20, left: 20, bottom: 50 };

  const thumbnailWidth = 70;
  const minThumbnailSpace = 4;
  const elementSpaces = [40, 80];
  const dotRadius = 12;
  const dotSpace = dotRadius * 3;

  const labelWidth = 140;
  const statHeight = 2.2 * dotSpace;

  const coverPos = 25;

  $: streamPos = data.ratio * thumbnailWidth + coverPos + margin.top + 30;

  $: streamHeight =
    height - streamPos - statHeight - margin.top - margin.bottom;
  $: statPos = streamPos + streamHeight + 10;

  $: colorCirlcePos = statPos;
  $: colorSchemePos = statPos + dotSpace;

  $: nextZoomStep = Math.ceil(zoom / zoomStep);
  $: currentZoomStep = Math.floor(zoom / zoomStep);
  $: currentZoomThreshold = (zoom % zoomStep) / zoomStep;

  $: currentElementSpace =
    elementSpaces[currentZoomStep] +
    (elementSpaces[nextZoomStep] - elementSpaces[currentZoomStep]) *
      currentZoomThreshold;

  $: currentThumbnailWidth = Math.min(
    currentElementSpace - minThumbnailSpace,
    thumbnailWidth
  );

  $: {
    availableWidth = width - labelWidth;
  }

  $: showsPages = currentZoomStep === 0;

  $: pages = map(data.pages, (page) => {
    const thumbnailHeight = Math.floor(thumbnailWidth * page.dimensions.ratio);
    const colorCircle = orderBy(
      filter(
        map(page.colorBins, (d, k) => ({
          bin: +k,
          value: d.value,
          color: d.avgColor,
        })),
        (d) => d.value > 0
      ),
      'value',
      'desc'
    );

    return {
      ...page,
      colorFingerprint: map(
        orderBy([...colorCircle].splice(0, 2), 'bin'),
        (d) => d.bin
      ).join('-'),
      colorCircle: colorCircle,
      panels: map(page.panels, (panel) => {
        const panelColorCircle = orderBy(
          filter(
            map(panel.colorBins, (d, k) => ({
              bin: +k,
              value: d.value,
              color: d.avgColor,
            })),
            (d) => d.value > 0
          ),
          'value',
          'desc'
        );

        return {
          ...panel,
          colorCircle: panelColorCircle,
          colorFingerprint: map(
            orderBy([...panelColorCircle].splice(0, 2), 'bin'),
            (d) => d.bin
          ).join('-'),
          thumbnail: {
            width: thumbnailWidth * panel.dimensions.width,
            height: Math.floor(thumbnailHeight * panel.dimensions.height),
            x: thumbnailWidth * panel.dimensions.x,
            y: thumbnailHeight * panel.dimensions.y,
            outerWidth: thumbnailWidth,
            outerHeight: thumbnailHeight,
          },
        };
      }),
      thumbnail: {
        width: thumbnailWidth,
        height: thumbnailHeight,
        x: 0,
        y: 0,
        outerWidth: thumbnailWidth,
        outerHeight: thumbnailHeight,
      },
    };
  });

  // $: panels = flatten(map(pages, (d) => d.panels));

  $: groupedStats = {
    colorScheme: createGroupedStats(pages, (d) =>
      d.colorScheme ? d.colorScheme.id : undefined
    ),
  };

  $: stackedStats = {
    colorScheme: createStacks(groupedStats.colorScheme),
  };

  $: connectedStats = {
    colorScheme: createConnections(groupedStats.colorScheme),
  };

  $: dotSpacer = Math.max(currentElementSpace / 3, dotRadius + 3);
  $: stripeWidth = showsPages ? thumbnailWidth / 4 : thumbnailWidth / 6;

  // elements
  // zooming

  $: {
    let currentWidth = thumbnailWidth / 2 + labelWidth;
    let tresholdSpacer = 1;
    let tresholdX = 1;

    if (showsPages) {
      currentStripeWidth = stripeWidth * currentZoomThreshold;
      currentStripeSpace = 2 * currentZoomThreshold;
      currentStripeOpacity = 1 - Math.min(currentZoomThreshold, 0.4);
    }

    elements = [];

    forEach(pages, (page) => {
      const stats = [];
      const pageThumbnailWidth = Math.min(
        currentThumbnailWidth,
        page.thumbnail.width
      );

      const hitarea = [
        {
          x: currentWidth - pageThumbnailWidth / 4,
          y: streamPos,
          width: pageThumbnailWidth / 2,
          height: streamHeight,
          type: 'element',
        },
        {
          x: currentWidth - dotSpace / 2,
          y: statPos + 10,
          width: dotSpace,
          height: dotSpace,
          type: 'colors',
        },
      ];

      if (page.colorScheme !== null) {
        hitarea.push({
          x: currentWidth - dotSpace / 2,
          y: statPos + dotSpace + 10,
          width: dotSpace,
          height: dotSpace + 3,
          type: 'colorscheme',
        });
      }

      elements.push({
        id: page.id,
        file: page.file,
        lightBins: page.lightBins,
        colorBins: page.colorBins,
        colorFingerprint: page.colorFingerprint,
        colorCircle: page.colorCircle,
        colorScheme: page.colorScheme,
        hitarea: hitarea,
        thumbnail: {
          ...page.thumbnail,
          width: pageThumbnailWidth,
          innerX: 'center',
          innerY: '0px',
          hasBorder: false,
        },
        x: currentWidth,
        showLabel: true,
        linePos: 30,
      });

      currentWidth += currentElementSpace;
    });

    fullWidth = currentWidth + margin.right;
  }

  $: {
    elementStacks = [];
    elementConnections = [];

    const positions = {
      colorScheme: colorSchemePos,
    };

    let stacks = [];
    let connections = [];

    stacks = stackedStats;
    connections = connectedStats;
    // stacks
    forEach(stacks, (items, k) => {
      let y = positions[k];
      forEach(items, (d) => {
        elementStacks.push({
          id: d.id,
          type: k,
          groupId: k === 'colorScheme' ? d.groupId : null,
          x: elements[d.positions[0]].x,
          y: y - dotRadius / 2,
          width: elements[d.positions[1]].x - elements[d.positions[0]].x,
          height: dotRadius,
        });
      });
    });

    // connections

    forEach(connections, (groups, k) => {
      let y1 = positions[k] + dotRadius;
      let y2 = positions[k] + dotRadius * 2;
      forEach(groups, (group) => {
        // const width = group.length;
        forEach(group, (d) => {
          const x1 = elements[d.positions[0]].x;
          const x2 = elements[d.positions[1]].x;
          elementConnections.push({
            id: d.id,
            groupId: k === 'colorScheme' ? d.groupId : null,
            path: `M ${x1} ${y1} L ${x1} ${y2} L ${x2} ${y2} L ${x2} ${y1}`,
          });
        });
        y2 += 5;
      });
    });
  }

  // stream

  $: colorBins = map(elements, (d) => {
    return {
      id: d.id,
      x: d.x,
      colorSchemeId: d.colorScheme !== null ? d.colorScheme.id : null,
      ...d.colorBins,
      isDimmed: d.isFirst,
      sum: sumBy(values(d.colorBins), 'value'),
    };
  });

  // dict of colors for hover

  $: {
    colorBinIndex = {};
    forEach(pages, (p) => {
      const bins = [{ id: p.id, bins: values(p.colorBins), sum: 1 }];
      forEach(bins, (bin) => {
        colorBinIndex[bin.id] = [];
        let currentValue = 0;
        forEach(values(bin.bins), (b) => {
          if (b.value > 0) {
            forEach(
              orderBy(b.colors, (c) => c[2], 'desc'),
              (c) => {
                const color = hslToRGB(c[0], c[1], c[2]);
                const bgColor = rgbToHex(color.r, color.g, color.b);
                const textColor =
                  contrast(color, { r: 0, g: 0, b: 0 }) > 8 ? '#000' : '#FFF';

                colorBinIndex[bin.id].push({
                  bgColor,
                  textColor,
                  start: currentValue / bin.sum,
                  end: (currentValue + c[3]) / bin.sum,
                });
                currentValue += c[3];
              }
            );
          }
        });
      });
    });
  }

  function getHoveredColor(colorId, value) {
    if (colorBinIndex[colorId] === undefined) {
      return null;
    }
    for (let c of colorBinIndex[colorId]) {
      if (value >= c.start && value <= c.end) {
        return { bgColor: c.bgColor, textColor: c.textColor };
      }
    }
    return null;
  }

  function copyToClipboard(text) {
    var dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  }

  $: stageOffset = (width - fullWidth) * pos;
</script>

<style lang="scss">
  @import './styles/theme.scss';

  .composition__wrapper {
    overflow: hidden;
    position: relative;
  }

  .composition__hit-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .composition__hit {
    position: absolute;
    // background-color: blue;
    // opacity: 0.4;
  }

  .composition__page {
    font-size: 12px;
    fill: #8a8a90;
  }
  .composition__stage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .composition__stage-images {
    position: relative;
    z-index: 0;
  }
  .composition__stage-labels {
    background-color: $c-blue-gray-05;
    border-left: 5px solid $c-blue-gray-0;
    z-index: 1;
    position: relative;
    box-shadow: 3px 0 6px hsla(236, 37%, 2%, 0.15);
  }
  .composition__stage-label-wrapper {
    box-sizing: border-box;
  }
  .composition__label {
    @include fluid-font-size($fs-xsmall);
    position: absolute;
    color: $c-blue-gray-5;
    text-transform: uppercase;
    width: 90%;
    text-align: right;
    letter-spacing: 1px;
  }
  .composition__img {
    position: absolute;
    background-repeat: no-repeat;
  }
  .has-border {
    border: 2px solid #000;
  }
  .composition__line {
    stroke: $c-blue-gray-2;
  }
  .composition__connection {
    stroke: $c-blue-gray-2;
    fill: none;
  }
  .composition__stack {
    fill: $c-blue-gray-3;
  }
</style>

<div
  class="composition__wrapper"
  style={`width: ${width}px; height: ${height}px;`}>
  <div class="composition__stage">
    <div
      class="composition__stage-images"
      style="transform: translate({stageOffset + margin.left}px,{margin.top}px)">
      {#each elements as p, i (p.id)}
        <div
          class="composition__img"
          class:has-border={p.thumbnail.hasBorder}
          style={`background-image: url('comics/${p.file}'); 
          left: ${p.x - currentThumbnailWidth / 2 + p.thumbnail.x}px; 
          top:${coverPos + p.thumbnail.y}px; 
          background-size: ${p.thumbnail.outerWidth}px ${p.thumbnail.outerHeight}px;
          background-position: ${p.thumbnail.innerX} ${p.thumbnail.innerY};
          height:${p.thumbnail.height}px; 
          width:${p.thumbnail.width}px;
          opacity:${(selectedColorScheme === null && hoveredColorScheme === null) || (p.colorScheme !== null && ((selectedColorScheme !== null && selectedColorScheme.id === p.colorScheme.id) || (hoveredColorScheme !== null && hoveredColorScheme.id === p.colorScheme.id))) ? 1 : 0.4}`}
          alt={p.id} />
      {/each}
    </div>
    <div
      class="composition__stage-labels"
      style={`width: ${labelWidth - 10}px; height: ${height}px; `}>
      <div class="composition__label" style={`top: ${coverPos}px`}>
        {#if currentZoomStep === 0}Pages{:else}Panels{/if}
      </div>
      <div class="composition__label" style={`top: ${streamPos}px`}>Colors</div>
      <div class="composition__label" style={`top: ${colorCirlcePos + 20}px`}>
        Color Circles
      </div>
      <div class="composition__label" style={`top: ${colorSchemePos + 20}px`}>
        Main Color Schemes
      </div>
    </div>
  </div>

  <svg class="composition" {width} {height}>
    <g transform={`translate(${margin.left + stageOffset},${margin.top})`}>
      <g>
        {#each elementStacks as d (d.id)}
          <rect
            class="composition__stack"
            x={d.x}
            y={d.y}
            opacity={(selectedColorScheme === null && hoveredColorScheme === null) || (selectedColorScheme !== null && selectedColorScheme.id === d.groupId) || (hoveredColorScheme !== null && hoveredColorScheme.id === d.groupId) ? 1 : 0.1}
            height={d.height}
            width={d.width} />
        {/each}
        {#each elementConnections as d (d.id)}
          <path
            d={d.path}
            opacity={(selectedColorScheme === null && hoveredColorScheme === null) || (selectedColorScheme !== null && selectedColorScheme.id === d.groupId) || (hoveredColorScheme !== null && hoveredColorScheme.id === d.groupId) ? 1 : 0.3}
            stroke="rgba(255,255,255,0.1)"
            class="composition__connection" />
        {/each}
      </g>
      <g class="composition__elements">
        {#each elements as p, i (p.id)}
          <g
            transform={`translate(${p.x},${0})`}
            opacity={(selectedColorScheme === null && hoveredColorScheme === null) || (p.colorScheme !== null && ((selectedColorScheme !== null && selectedColorScheme.id === p.colorScheme.id) || (hoveredColorScheme !== null && hoveredColorScheme.id === p.colorScheme.id))) ? 1 : 0.3}>
            <line class="composition__line" y1={p.linePos} y2={statPos} />
            <text
              class="composition__page"
              x={0}
              y="10"
              text-anchor="middle"
              opacity={p.showLabel ? (hoveredColorScheme === null || (p.colorScheme !== null && hoveredColorScheme.id === p.colorScheme.id) ? 1 : 0.4) : 0.3}>
              {p.id}
            </text>
            <ColorCircle
              x={0}
              y={colorCirlcePos}
              width={dotRadius * 2}
              height={dotRadius * 2}
              colors={p.colorCircle} />
            {#if p.colorScheme}
              <ColorScheme
                x={0}
                y={colorSchemePos}
                width={dotRadius * 2}
                height={dotRadius * 2}
                colors={p.colorScheme.bins} />
            {:else}
              <Dot x={0} y={colorSchemePos} r={dotRadius} />
            {/if}
          </g>
        {/each}
      </g>
    </g>

    <g transform={`translate(${margin.left},${streamPos})`}>
      <PageColorBumpChart
        {bins}
        elements={colorBins}
        offset={labelWidth}
        {stageOffset}
        stripeWidth={currentStripeWidth}
        stripeSpace={currentStripeSpace}
        stripeOpacity={currentStripeOpacity}
        stripeBuffer={currentElementSpace * 1.5}
        {width}
        {hoveredColorScheme}
        {selectedColorScheme}
        height={streamHeight}
        {showsPages} />
    </g>
  </svg>
  <div
    class="composition__hit-area"
    style="transform: translate({stageOffset + margin.left}px,{0}px)">
    {#each elements as p (p.id)}
      {#each p.hitarea as { x, y, width, height, type }}
        <div
          class="composition__hit"
          style={`width: ${width}px; left: ${x}px; top: ${y}px; height:${height}px;`}
          on:mouseover={(e) => {
            if (type === 'element') {
              if ((currentZoomStep === 0 && currentZoomThreshold > 0.05) || currentZoomStep !== 0) {
                hoveredElement = p;
              }
            } else if (type === 'colorscheme') {
              hoveredColorScheme = p.colorScheme;
            } else if (type === 'colors') {
              hoveredCopyColor = true;
            }
          }}
          on:mousemove={(e) => {
            if (type === 'element') {
              if ((currentZoomStep === 0 && currentZoomThreshold > 0.05) || currentZoomStep !== 0) {
                const value = Math.min(Math.max(0, e.offsetY / height), 1);
                hoveredColor = getHoveredColor(hoveredElement.id, value);
              }
            }
          }}
          on:click={(e) => {
            if (type === 'element' && hoveredColor !== null) {
              copyToClipboard(hoveredColor.bgColor);
            } else if (type === 'colors') {
              copyToClipboard(map(colorBinIndex[p.id], (d) => d.bgColor).join(','));
            } else if (type === 'colorscheme') {
              if (selectedColorScheme !== null) {
                if (selectedColorScheme.id === p.colorScheme.id) {
                  selectedColorScheme = null;
                } else {
                  selectedColorScheme = p.colorScheme;
                }
              } else {
                selectedColorScheme = p.colorScheme;
              }
            }
          }}
          on:mouseout={() => {
            if (type === 'element') {
              hoveredElement = null;
              hoveredColor = null;
            } else if (type === 'colorscheme') {
              hoveredColorScheme = null;
            } else if (type === 'colors') {
              hoveredCopyColor = null;
            }
          }} />
      {/each}
    {/each}
  </div>
</div>
