<script>
  import Bar from "./Bar.svelte";

  export let width = 100;
  export let height = 30;
  export let barWidth = 0;
  export let isDeactivated = false;
  export let position = 0;

  const barSpace = 4;

  let container;
  let offset;

  $: barHeight = height - barSpace * 2;
  $: internalWidth = width - barWidth;
  $: internalPosFactor = internalWidth / width;
  $: internalPos = position * internalPosFactor;

  function startListener(event) {
    const { bbox } = event.detail;
    offset = event.detail.x - bbox.left;
    document.body.style.cursor = "pointer";
  }

  function moveListener(event) {
    const { x } = event.detail;
    position = (x - offset) / internalWidth;
    if (position < 0) {
      position = 0;
    } else if (position > 1) {
      position = 1;
    }
  }

  function endListener() {
    document.body.style.cursor = "";
  }
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  .scrollbar {
    position: relative;
    background-color: $c-black;
  }
</style>

<div class="scrollbar" style="height: {height}px; width: {width}px;">
  <div bind:this={container}>
    <Bar
      height={barHeight}
      width={barWidth}
      {barSpace}
      position={internalPos}
      {isDeactivated}
      on:dragstart={startListener}
      on:dragging={moveListener}
      on:dragend={endListener} />
  </div>
</div>
