<script>
  import { forEach, orderBy, map, range, fromPairs, sumBy } from 'lodash-es';
  import {
    stack as d3stack,
    area as d3area,
    stackOrderNone,
    stackOffsetNone,
  } from 'd3-shape';

  import { scaleLinear } from 'd3-scale';

  export let colors = [];
  export let bins = [];
  export let size = 400;
  export let prefix = '';

  let series = [];
  const itemSpace = 0.6;
  const maxHeight = 40;

  $: width = size;
  $: height = size;

  $: innerRadius = colors.length * itemSpace;
  $: radiusOffset = size / 20 + (maxHeight * colors.length) / 100;

  $: circleStep = (Math.PI * 1.95) / colors.length;

  $: {
    const stack = d3stack()
      .keys(bins)
      .value((d, key) => {
        return d[key].value;
      })
      .order(stackOrderNone)
      .offset(stackOffsetNone);

    series = stack(colors);
  }

  $: streams = map(series, (pages, i) => {
    const stream = { id: bins[i], colors: [], connections: [] };
    let px1 = 0;
    let px2 = 0;
    let py1 = 0;
    let py2 = 0;
    let pValue = 0;
    let pColors = [];
    let pAvgColor = {};

    forEach(pages, (page, pageIndex) => {
      const bin = page.data[bins[i]];
      const angle = circleStep * pageIndex - Math.PI / 2;
      const dist1 =
        innerRadius + ((1 - page[0]) / page.data.sum) * radiusOffset;
      const dist2 =
        innerRadius + ((1 - page[1]) / page.data.sum) * radiusOffset;

      const x1 = size / 2 + Math.cos(angle) * dist1;
      const y1 = size / 2 + Math.sin(angle) * dist1;

      const x2 = size / 2 + Math.cos(angle) * dist2;
      const y2 = size / 2 + Math.sin(angle) * dist2;

      if (pageIndex > 0 && bin.value > 0 && pValue > 0) {
        const xm1 = y1 === y2 ? x1 : Math.abs(x2 - x1) / 2;
        const xm2 = px1 === px2 ? px1 : Math.abs(px2 - px1) / 2;
        const ym1 = y1 === y2 ? y1 : Math.abs(y2 - y1) / 2;
        const ym2 = py1 === py2 ? py1 : Math.abs(py2 - py1) / 2;
        const angle = Math.atan2(ym2 - ym1, xm2 - xm1);

        stream.connections.push({
          id: bin.id,
          x1,
          x2,
          x3: px1,
          x4: px2,
          y1: y1,
          y2: y2,
          y3: py1,
          y4: py2,
          color1: bin.avgColor,
          color2: pAvgColor,
          angle,
        });
      }
      px1 = x1;
      px2 = x2;
      py1 = y1;
      py2 = y2;
      pValue = bin.value;
      pColors = bin.colors;
      pAvgColor = bin.avgColor;
    });
    return stream;
  });
</script>

<style lang="scss">
  @import './styles/theme.scss';
</style>

<svg class="circle-bumpchart" {width} {height}>
  <defs>
    {#each streams as { id, colors, connections } (id)}
      {#each connections as { color1, color2, angle, id } (id)}
        <linearGradient
          gradientTransform="rotate({angle})"
          id="gradient-{prefix}-{id}">
          <stop
            offset="0%"
            stop-color={`hsl(${color2[0]},${color2[1]}%,${color2[2]}%)`} />
          <stop
            offset="100%"
            stop-color={`hsl(${color1[0]},${color1[1]}%,${color1[2]}%)`} />
        </linearGradient>
      {/each}
    {/each}
  </defs>
  {#each streams as { id, colors, connections } (id)}
    {#each connections as { x1, x2, x3, x4, y1, y2, y3, y4, id } (id)}
      <path
        {id}
        d={`M ${x3},${y3} L ${x1},${y1} L ${x2},${y2} L ${x4},${y4} Z`}
        fill={`url(#gradient-${prefix}-${id})`} />
    {/each}
  {/each}
</svg>
