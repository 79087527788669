<script>
  export let url = "";
  export let text = "";
  export let hashtags = "";

  $: encodedUrl = encodeURIComponent(url);
  $: encodedText = encodeURIComponent(text.replace(/\s+/g, " "));
  $: encodedHashtags = encodeURIComponent(hashtags);
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  .sharing {
    margin-left: $sp-medium;
    padding-left: $sp-medium;
    align-items: center;
    display: flex;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }
    li {
      display: inline-block;
    }
    .sharing__button {
      background-repeat: no-repeat;
      background-position: center;
      a {
        width: 20px;
        height: 20px;
        transition: opacity 0.2s;
        display: block;
        color: transparent;
        line-height: 0;
        padding: 5px;
        opacity: 0.2;

        .sharing__text {
          display: none;
        }
      }
      a:hover {
        opacity: 1;
      }
    }

    .sharing__icon {
      position: relative;
      width: 24px;
      height: 24px;
      background-position-x: center;
      display: inline-block;
      padding-left: 0;
      &.is-facebook {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ctitle%3Eicon__facebook%3C/title%3E%3Cpath d='M15.73,12.34l-2.73.1v7.88H9.81V12.44H7.2V9.34H9.91V7.09a3.94,3.94,0,0,1,1.1-3,4,4,0,0,1,2.85-1q.85,0,1.49,0l.86,0V5.88H14.6a1.38,1.38,0,0,0-1.2.43,1.84,1.84,0,0,0-.3,1.07V9.24h3Z' fill='%23fff'/%3E%3C/svg%3E");
      }
      &.is-twitter {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ctitle%3Eicon__twitter%3C/title%3E%3Cpath d='M20.7,6.14a7.66,7.66,0,0,1-.83,1.1,5.45,5.45,0,0,1-1.13.94v.5a10.52,10.52,0,0,1-.66,3.58A10.29,10.29,0,0,1,12.88,18a9.84,9.84,0,0,1-4.44,1,10,10,0,0,1-3-.45A9.45,9.45,0,0,1,2.7,17.13a1.48,1.48,0,0,0,.44.09h.44A7.29,7.29,0,0,0,6,16.83a7.62,7.62,0,0,0,2.11-1.15A3.35,3.35,0,0,1,6,15a3.84,3.84,0,0,1-1.31-1.82.87.87,0,0,0,.35.09H5.9a1.6,1.6,0,0,0,.49-.09A3.37,3.37,0,0,1,4.31,11.9a3.61,3.61,0,0,1-.84-2.34,3.33,3.33,0,0,0,.8.35,3.37,3.37,0,0,0,1,.13A3.31,3.31,0,0,1,4,8.77,3.53,3.53,0,0,1,3.57,7,3.8,3.8,0,0,1,3.7,6a3.56,3.56,0,0,1,.35-.86A10.34,10.34,0,0,0,5.54,6.63,10.72,10.72,0,0,0,7.33,7.8a10.52,10.52,0,0,0,2,.8A10.11,10.11,0,0,0,11.56,9a1.48,1.48,0,0,1-.09-.44q0-.22,0-.42a3.59,3.59,0,0,1,1.08-2.62,3.66,3.66,0,0,1,4.12-.76,4.25,4.25,0,0,1,1.23.85,11.8,11.8,0,0,0,1.24-.37,6.87,6.87,0,0,0,1.1-.51,3.52,3.52,0,0,1-.65,1.17,4.22,4.22,0,0,1-1,.87,6.29,6.29,0,0,0,1.1-.21A9.22,9.22,0,0,0,20.7,6.14Z' fill='%23fff'/%3E%3C/svg%3E");
      }
      &.is-linkedin {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' enable-background='new 0 0 24 24'%3E%3Cpath d='m18.7 4c.4 0 .7.1.9.4s.4.5.4.8v13.4c0 .4-.1.7-.4 1-.2.3-.6.4-.9.4h-13.3c-.4 0-.7-.1-1-.4-.3-.3-.4-.6-.4-1v-13.4c0-.4.1-.7.4-.9s.6-.3 1-.3h13.3m-11.7 4.8c.2.2.5.3.9.3.4 0 .7-.1.9-.4.2-.2.3-.5.3-.8 0-.3-.1-.6-.3-.9s-.5-.3-.9-.3-.6.1-.9.3-.3.6-.3.9c0 .3.1.6.3.9m2 8.5v-7.1h-2.3v7.1h2.3m8.3 0v-4.2c0-1-.2-1.8-.7-2.3-.5-.5-1.1-.8-1.9-.8-.8 0-1.5.4-2.1 1.2v-1h-2.3v7.1h2.3v-4c0-.3 0-.5.1-.6.2-.6.6-.8 1.2-.8.8 0 1.2.5 1.2 1.5v3.9h2.2' fill='%23fff'/%3E%3C/svg%3E");
      }
    }
  }
</style>

<ul class="sharing">
  <li class="sharing__button twitter">
    <a
      href={`https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedUrl}&hashtags=${encodedHashtags}`}
      target="_blank"
      title="Share on Twitter"
      role="button"
      aria-label="Share on Twitter"
      rel="noopener noreferrer">
      <span class="sharing__icon is-twitter" />
      <span class="sharing__text">tweet</span>
    </a>
  </li>
  <li class="sharing__button facebook">
    <a
      href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
      target="_blank"
      title="Share on Facebook"
      role="button"
      aria-label="Share on Facebook"
      rel="noopener noreferrer">
      <span class="sharing__icon is-facebook" />
      <span class="sharing__text">share</span>
    </a>
  </li>
  <li class="sharing__button linkedin">
    <a
      href={`https://www.linkedin.com/cws/share?url=${encodedUrl}`}
      target="_blank"
      title="Share on LinkedIn"
      role="button"
      aria-label="Share on LinkedIn"
      rel="noopener noreferrer">
      <span class="sharing__icon is-linkedin" />
      <span class="sharing__text">share</span>
    </a>
  </li>
</ul>
