<script>
  export let options = [];
  export let selected = "";
</script>

<style lang="scss">
  @import "./styles/theme.scss";

  .tabs {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }
  span {
    @include fluid-font-size($fs-small);
    color: $c-blue-gray-6;
    cursor: pointer;
    padding: 0.5em 1em;
    white-space: nowrap;
    // margin: 0.5em 1em;
    border: 1px solid $c-blue-gray-3;
  }

  .selected,
  span:hover {
    color: $c-white;
    background-color: $c-blue-gray-1;
    border-right: 1px solid $c-blue-gray-3;
  }
</style>

<div class="tabs">
  {#each options as { label, id } (id)}
    <span on:click={() => (selected = id)} class:selected={selected == id}>
      {label}
    </span>
  {/each}
</div>
