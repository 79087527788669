<script>
  import { throttle } from 'lodash-es';
  import Zoombar from './zoombar/Zoombar.svelte';
  export let width = 0;
  export let height = 0;
  export let position = 0;

  function updatePosition(d) {
    position = d.detail;
  }
</script>

<style lang="scss">
  @import './styles/theme.scss';
  .zoom {
    background-color: $c-blue-gray-1;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-top: 25px;
  }
  .zoombar__wrapper {
    flex: 1;
    display: flex;
    align-items: center;
  }
  .zoom__header,
  .zoom__label {
    @include fluid-font-size($fs-xsmall);
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .zoom__header {
    color: $c-blue-gray-5;
  }
  .zoom__label {
    color: $c-blue-gray-5;
    transform: rotate(-90deg);
  }
  .zoom__label-top {
    margin-bottom: 45%;
  }
  .zoom__label-bottom {
    margin-top: 45%;
  }
</style>

<!-- <svelte:window on:wheel={onScroll} /> -->

<div class="zoom" style={`width: ${width}px; height: ${height}px; `}>
  <div class="zoom__header">Zoom</div>
  <div class="zoombar__wrapper">
    <div class="zoombar__inner-wrapper">
      <div class="zoom__label zoom__label-top">Comic</div>
      <Zoombar
        {position}
        {width}
        barWidth={12}
        thumbSize={30}
        height={height / 3}
        ticks={[0.01, 0.975]}
        on:change={throttle(updatePosition, 100)} />
      <div class="zoom__label zoom__label-bottom">Page</div>
    </div>
  </div>
</div>
