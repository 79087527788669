export function pointerEvents() {
  if (document && 'ontouchstart' in document.documentElement) {
    return {
      start: 'touchstart',
      move: 'touchmove',
      end: 'touchend',
    };
  }
  return {
    start: 'mousedown',
    move: 'mousemove',
    end: 'mouseup',
  };
}

export function isTouchDevice() {
  return (
    'ontouchstart' in window ||
    navigator.MaxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
}

export function getY(event) {
  if (event.touches) {
    return event.touches[0].clientY;
  }
  return event.clientY;
}

export function getX(event) {
  if (event.touches) {
    return event.touches[0].clientX;
  }
  return event.clientX;
}
