<script>
  import { createEventDispatcher } from "svelte";
  import { getY } from "../../../utils/events";
  import Thumb from "./Thumb.svelte";

  export let width = 30;
  export let height = 100;
  export let barWidth = 2;
  export let thumbSize = 30;
  export let position = 0;
  export let ticks = [];

  const dispatch = createEventDispatcher();

  let container;
  let offset;

  $: barCenter = (width - barWidth) / 2;
  $: containerOffset = container ? container.getBoundingClientRect().top : 0;
  $: tickWidth = barWidth / 3;

  function startListener(event) {
    const { bbox } = event.detail;
    offset = bbox.width / 2 - (event.detail.y - bbox.top) + containerOffset;
    document.body.style.cursor = "pointer";
  }

  function moveListener(event) {
    const { y } = event.detail;
    position = (y - offset) / height;
    if (position < 0) {
      position = 0;
    } else if (position > 1) {
      position = 1;
    }
    dispatch("change", position);
  }

  function endListener() {
    document.body.style.cursor = "";
  }

  function handleClick(event) {
    position = (getY(event) - containerOffset) / height;
    if (position < 0) {
      position = 0;
    } else if (position > 1) {
      position = 1;
    }
    dispatch("change", position);
  }
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  .zoombar {
    position: relative;
  }
  .zoombar__rail {
    position: relative;
    background-color: $c-blue-gray-0;
  }
  .zoombar__tick {
    position: absolute;
    background-color: $c-blue-gray-3;
    border-radius: 100%;
  }
</style>

<div class="zoombar" style="height: {height}px; width: {width}px;">
  <div
    class="zoombar__rail"
    bind:this={container}
    style="left: {barCenter}px; width: {barWidth}px; height: {height}px;
    border-radius: {barWidth}px "
    on:click={handleClick}>
    {#if ticks.length > 0}
      {#each ticks as tick}
        <div
          class="zoombar__tick"
          style="left:{barWidth / 2 - tickWidth / 2}px; top: {tick * 100}%;
          width: {tickWidth}px; height: {tickWidth}px;" />
      {/each}
    {/if}
    <Thumb
      size={thumbSize}
      x={barWidth / 2}
      {position}
      on:dragstart={startListener}
      on:dragging={moveListener}
      on:dragend={endListener} />
  </div>
</div>
