<script>
  import ComicCircleBumpchart from './ComicCircleBumpchart.svelte';
  import ComicCircleConnections from './ComicCircleConnections.svelte';
  import ColorSchemes from './ColorSchemes.svelte';
  import { bins } from '../../utils/colors.js';
  import { isTouchDevice } from '../../utils/events.js';

  import { viewId } from '../../state/viewState.js';
  import { selectedItemId } from '../../state/selectionState.js';
  import { scrollPos } from '../../state/uiState.js';
  import { sumBy } from 'lodash-es';

  export let id = '';
  export let shortId = '';
  export let name = '';
  export let year = '';
  export let colorSchemes = '';
  export let colorBins = [];
  export let stacks = [];
  export let connections = [];
  export let cover = '';
  export let pageNum = '';

  let showDetails = false;

  $: years = Array.isArray(year) ? year.join(', ') : year;
  $: hasColorSchemes = sumBy(colorSchemes, 'count') !== 0;
  $: hasMouse = !isTouchDevice();
</script>

<style lang="scss">
  @import './styles/theme.scss';
  .thumbnail {
    position: relative;
    align-self: start;
    z-index: 0;
    cursor: zoom-in;
    box-sizing: border-box;
    border: 1px solid $c-blue-gray-1;
    transition: all 200ms ease-in-out;
    width: 300px;
    @include fluid-space(margin-left margin-right, $sp-xxsmall);
    @include fluid-space(margin-bottom, $sp-small);

    &:hover {
      transform: scale(1.02);
      z-index: 10;
      border: 1px solid $c-blue-gray-7;
      box-shadow: 0 1px 10px rgba(255, 255, 255, 0.3);
      .thumbnail__teaser {
        display: block;
      }
    }
  }
  .thumbnail__header {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid $c-blue-gray-1;
    line-height: 1.45;
    img {
      margin-right: 1em;
    }

    h3 {
      @include fluid-font-size($fs-base);
      @include fluid-space(margin-bottom, $sp-xxsmall);
      line-height: 1.35;
      white-space: normal;
    }

    .thumbnail__header-text {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .thumbnail__header-subtext {
      color: $c-blue-gray-5;
      @include fluid-font-size($fs-small);
    }
  }
  .thumbnail__color-schemes {
    padding: 10px;
    border-top: 1px solid $c-blue-gray-1;
  }
  .thumbnail__stage {
    position: relative;
  }
  .thumbnail__overlaps {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    opacity: 0;
  }
  .thumbnail__header,
  .thumbnail__content {
    pointer-events: none;
  }
  .is-visible {
    opacity: 1;
  }
</style>

<div
  class="thumbnail"
  on:click={() => {
    $viewId = 'comic';
    $selectedItemId = id;
    $scrollPos = window.scrollY;
  }}
  on:mouseover={() => {
    if (hasMouse) {
      showDetails = true;
    }
  }}
  on:mouseout={() => {
    if (hasMouse) {
      showDetails = false;
    }
  }}>
  <header class="thumbnail__header">
    <img src={cover} alt="Cover of {name}" style="height: 100px; width: auto" />
    <div class="thumbnail__header-text">
      <h3>{name}</h3>
      <p class="thumbnail__header-subtext">{years}</p>
      <p class="thumbnail__header-subtext">{pageNum} pages</p>
    </div>
  </header>
  <div class="thumbnail__content">
    <div class="thumbnail__stage">
      <ComicCircleBumpchart
        size="300"
        colors={colorBins}
        {bins}
        prefix={shortId} />
      <div class="thumbnail__overlaps" class:is-visible={showDetails}>
        <ComicCircleConnections
          size="300"
          colorNum={colorBins.length}
          {stacks}
          {connections} />
      </div>
    </div>
    {#if hasColorSchemes}
      <div class="thumbnail__color-schemes">
        <ColorSchemes items={colorSchemes} {showDetails} />
      </div>
    {/if}
  </div>
</div>
