<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { pointerEvents, getY, getX } from "../../../utils/events";

  export let height = 0;
  export let width = 0;
  export let barSpace = 0;
  export let position = 0;
  export let isDeactivated = true;

  let isActive = false;
  let bar;
  let bbox;
  let events = pointerEvents();
  const dispatch = createEventDispatcher();

  function handleStart(event) {
    event.preventDefault();
    const x = getX(event);
    const bbox = event.target.getBoundingClientRect();
    dispatch("dragstart", { x, bbox });
    window.addEventListener(events.move, handleMove);
    window.addEventListener(events.end, handleEnd);
    isActive = true;
  }

  function handleMove(event) {
    event.preventDefault();
    const x = getX(event);
    const bbox = event.target.getBoundingClientRect();
    dispatch("dragging", { x, bbox });
  }

  function handleEnd(event) {
    event.preventDefault();
    dispatch("dragend");
    window.removeEventListener(events.move, handleMove);
    window.removeEventListener(events.end, handleEnd);
    isActive = false;
  }

  onMount(() => {
    bar.addEventListener(events.start, handleStart);
  });
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  .bar {
    position: absolute;
    background: $c-blue-gray-3;
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    &:hover,
    &.is-active {
      cursor: pointer;
      background: $c-blue-gray-5;
    }
    &.is-deactivated {
      pointer-events: none;
      display: none;
      .bar__stripe {
        display: none;
      }
    }
  }
  .bar__stripe {
    position: absolute;
    border-left: 1px solid rgba(255, 255, 255, 0.6);
    height: 60%;
    top: 20%;
    width: 1px;
    left: 50%;
  }
  .bar__stripe:first-child {
    transform: translate(-4px);
  }
  .bar__stripe:last-child {
    transform: translate(4px);
  }
</style>

<div
  class="bar"
  bind:this={bar}
  class:is-active={isActive}
  class:is-deactivated={isDeactivated}
  style="height: {height - barSpace}px; width: {width}px; top: {barSpace}px;
  left: {position * 100}%">
  <div class="bar__stripe" />
  <div class="bar__stripe" />
  <div class="bar__stripe" />
</div>
