<script>
  import { onMount, tick } from "svelte";
  import { get } from "svelte/store";

  // list of stores to sync with URL state
  export let stores = [];

  function updateHash() {
    // simple encoding <store1>/<store2>/…
    return tick().then(() => {
      window.location.hash = stores.map(get).join("/");
    });
  }

  function hashchange() {
    const h = window.location.hash.slice(1).split("/");
    // update stores
    h.forEach(
      (value, i) =>
        stores[i] &&
        stores[i] !== value &&
        stores[i].set &&
        stores[i].set(value)
    );
  }

  onMount(() => {
    hashchange();
    // update hash when stores change
    stores.forEach(p => p.subscribe(updateHash));
  });
</script>

<svelte:window on:hashchange={hashchange} />
