<script>
  import { map, flatten, uniq } from 'lodash-es';

  export let colorNum = 10;
  export let size = 400;
  export let stacks = [];
  export let connections = [];

  const itemSpace = 0.6;
  const maxHeight = 40;

  function polarToCartesian(centerX, centerY, radius, angleInRadians) {
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  }

  function describeArc(x, y, radius, startAngle, endAngle, arcType) {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);

    const largeArcFlag = endAngle - startAngle <= Math.PI ? '0' : '1';

    const d = [
      'M',
      start.x,
      start.y,
      'A',
      radius,
      radius,
      0,
      largeArcFlag,
      0,
      end.x,
      end.y,
    ].join(' ');

    return d;
  }

  $: width = size;
  $: height = size;
  $: centerX = width / 2;
  $: centerY = height / 2;

  $: radius = colorNum * itemSpace - 10;
  $: circleStep = (Math.PI * 1.95) / colorNum;
  $: circleStepHalf = circleStep / 2;

  $: stackItems = map(stacks, (d) => {
    const angle1 = d.positions[0] * circleStep;
    const angle2 = d.positions[1] * circleStep;
    return {
      id: d.id,
      path: describeArc(centerX, centerY, radius, angle1, angle2),
    };
  });

  $: connectionItems = map(connections, (d) => {
    const angle1 = d.positions[1] * circleStep;
    const angle2 = d.positions[0] * circleStep;
    return {
      id: d.id,
      path: describeArc(centerX, centerY, radius, angle1, angle2, true),
    };
  });
  $: connectionDotItems = map(
    uniq(flatten(connections.map((d) => flatten(d.positions)))),
    (d) => {
      const { x, y } = polarToCartesian(
        centerX,
        centerY,
        radius,
        d * circleStep
      );

      return {
        id: d,
        x,
        y,
      };
    }
  );
</script>

<style lang="scss">
  @import './styles/theme.scss';
  .circle-connections path {
    fill: none;
    stroke: $c-blue-gray-3;
  }
  .circle-connections__stacks {
    stroke-width: 3px;
  }
  .circle-connections__connections {
    stroke-width: 0.5px;
  }
  .circle-connections__dots {
    fill: $c-blue-gray-5;
  }
</style>

<svg class="circle-connections" {width} {height}>
  <g class="circle-connections__stacks">
    {#each stackItems as { id, path } (id)}
      <path d={path} />
    {/each}
  </g>
  <g class="circle-connections__connections">
    {#each connectionItems as { id, path } (id)}
      <path d={path} />
    {/each}
  </g>
  <g class="circle-connections__dots">
    {#each connectionDotItems as { id, x, y } (id)}
      <circle cx={x} cy={y} r="1" />
    {/each}
  </g>
</svg>
