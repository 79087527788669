<script>
  export let isFullWidth = false;
</script>

<style type="text/scss">
  @import "./styles/theme.scss";
  .wrapper {
    width: 85%;
    margin: 0 auto;

    @media #{$m-min-sm} {
      width: 80%;
    }
    @media #{$m-min-lg} {
      width: 72%;
    }
    // @media #{$m-min-xl} {
    //   width: 65%;
    // }

    &.is-full-width {
      width: 90%;
    }
  }
</style>

<div class="wrapper" class:is-full-width={isFullWidth}>
  <slot />
</div>
