<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { pointerEvents, getY } from "../../../utils/events";

  export let position;
  export let x;
  export let size;

  let thumb;
  let bbox;
  let events = pointerEvents();
  let isActive = false;
  const dispatch = createEventDispatcher();

  function handleStart(event) {
    event.preventDefault();
    const y = getY(event);
    const bbox = event.target.getBoundingClientRect();
    dispatch("dragstart", { y, bbox });
    window.addEventListener(events.move, handleMove);
    window.addEventListener(events.end, handleEnd);
    isActive = true;
  }

  function handleMove(event) {
    event.preventDefault();
    const y = getY(event);
    const bbox = event.target.getBoundingClientRect();
    dispatch("dragging", { y, bbox });
  }

  function handleEnd(event) {
    event.preventDefault();
    dispatch("dragend");
    window.removeEventListener(events.move, handleMove);
    window.removeEventListener(events.end, handleEnd);
    isActive = false;
  }

  onMount(() => {
    thumb.addEventListener(events.start, handleStart);
  });
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  .thumb {
    position: absolute;
    border-radius: 100%;
    background: $c-blue-gray-3;
    border: 1px solid rgba(255, 255, 255, 0.1);
    touch-action: none;
    transform: translate(-50%, -50%);
    transition: 0.2s height, 0.2s width;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
    &:hover,
    &.is-active {
      background: $c-blue-gray-5;
      cursor: pointer;
    }
  }
  .thumb__stripe {
    position: absolute;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    height: 1px;
    top: 45%;
    width: 40%;
    left: 30%;
  }
  .thumb__stripe:first-child {
    transform: translate(0, -4px);
  }
  .thumb__stripe:last-child {
    transform: translate(0, 4px);
  }
</style>

<div
  bind:this={thumb}
  class="thumb"
  class:is-active={isActive}
  style="left: {x}px; top: {position * 100}%; width: {size}px; height: {size}px;"
  on:start={handleStart}
  on:move={handleMove}
  on:end={handleEnd}>
  <div class="thumb__stripe" />
  <div class="thumb__stripe" />
  <div class="thumb__stripe" />
</div>
