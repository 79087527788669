export const getCentroid2d = (face2d, offset) => {
  const centroidX =
    face2d.reduce((acc, current) => (acc += current.x), 0) /
    (face2d.length * offset);
  const centroidY =
    face2d.reduce((acc, current) => (acc += current.y), 0) /
    (face2d.length * offset);

  return { x: centroidX, y: centroidY };
};

export const getPoint2dBetweenVectors = (vec1, vec2, fraction) => {
  return {
    x: fraction * vec1.x + (1 - fraction) * vec2.x,
    y: fraction * vec1.y + (1 - fraction) * vec2.y
  };
};

export const euclidDist2d = (a, b) => {
  return Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2);
};

export const coordsIncircle = (a, b, c) => {
  const ab = euclidDist2d(a, b);
  const bc = euclidDist2d(b, c);
  const ac = euclidDist2d(a, c);

  return {
    x: (bc * a.x + ac * b.x + ab * c.x) / (ab + bc + ac),
    y: (bc * a.y + ac * b.y + ab * c.y) / (ab + bc + ac)
  };
};

export const radiusIncircle = (a, b, c) => {
  const ab = euclidDist2d(a, b);
  const bc = euclidDist2d(b, c);
  const ac = euclidDist2d(a, c);

  return (
    0.5 *
    Math.sqrt(
      ((ac + ab - bc) * (ab + bc - ac) * (bc + ac - ab)) / (bc + ac + ab)
    )
  );
};

export const circleCircleCollision = (x1, y1, d1, x2, y2, d2) => {
  if (d1 < 0 || d2 < 0) return undefined;
  var c = Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2));
  var a = d2;
  var b = d1;
  var A = Math.acos((b * b + c * c - a * a) / (2 * b * c));
  if (isNaN(A)) {
    return undefined;
  }
  var theta = Math.atan2(y2 - y1, x2 - x1);
  var phi1 = theta + A;
  var phi2 = theta - A;
  return [phi1, phi2];
};

export const circleCircleIntersect = (x1, y1, d1, x2, y2, d2) => {
  if (d1 < 0 || d2 < 0) return undefined;
  var c = Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2));

  if (d1 > c + d2 || d2 > c + d1) {
    return true;
  } else {
    return false;
  }
};


export const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  return {
    x: centerX + radius * Math.cos(angleInDegrees),
    y: centerY + radius * Math.sin(angleInDegrees)
  };
};

export const describeArc = (x, y, radius, startAngle, endAngle) => {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var largeArcFlag = endAngle - startAngle <= Math.PI ? '0' : '1';

  var d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y
  ].join(' ');

  return d;
};
