<script>
  import Router from "./components/utils/Router.svelte";
  import { isReady } from "./data/dataAPI";
  import { viewId } from "./state/viewState.js";
  import { selectedItemId, selectedItem } from "./state/selectionState.js";

  import Comic from "./views/Comic.svelte";
  import Comics from "./views/Comics.svelte";
</script>

<style lang="scss" global>
  @import "./styles/reset.scss";
  @import "./styles/base.scss";

  body.no-scroll {
    overscroll-behavior-x: none;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .app {
    height: 100%;
    display: flex;
    flex-direction: column;
    color: $c-white;
  }
</style>

<Router stores={[viewId, selectedItemId]} />

<div class="app">
  {#if isReady}
    {#if $viewId === 'comics'}
      <Comics />
    {:else if $viewId === 'comic' && $selectedItem !== undefined}
      <Comic />
    {/if}
  {/if}
</div>
