<script>
  export let isCentered = false;
  export let hasSmallMargin = false;
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  .subheader {
    letter-spacing: 2px;
    text-transform: uppercase;
    @include fluid-font-size($fs-small);

    color: $c-blue-gray-4;

    &.is-centered {
      text-align: center;
    }
    &.has-small-margin {
      @include fluid-space(margin-bottom, $sp-small);
    }
  }
</style>

<div
  class="subheader"
  class:is-centered={isCentered}
  class:has-small-margin={hasSmallMargin}>
  <slot />
</div>
