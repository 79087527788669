<script>
  import ColorScheme from "../vis/ColorScheme.svelte";
  import { colorSchemesExamples } from "../../utils/colors";

  export let items = [];
  export let showDetails = false;

  const size = 24;
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  .colorschemes {
    display: flex;
    justify-content: center;
  }
  .color-scheme {
    display: flex;
    flex-direction: column;
    margin: 0 0.2em;
  }
  .color-scheme__label {
    color: $c-blue-gray-3;
    @include fluid-font-size($fs-xsmall);
    text-align: center;
  }
</style>

<div class="colorschemes">
  {#each items as { name, id, count } (id)}
    {#if count !== 0}
      <div class="color-scheme">
        <svg width={size} height={size}>
          <ColorScheme
            x={size / 2}
            y={size / 2}
            width={size - 2}
            height={size - 2}
            colors={colorSchemesExamples[id]} />
        </svg>
        {#if showDetails}
          <span class="color-scheme__label">{count}</span>
        {/if}
      </div>
    {/if}
  {/each}
</div>
