<script>
  import mousePos from "../../state/mouseState.js";

  export let width = 500;
  let windowWidth = 0;

  $: halfWidth = (width + 20) / 2;
  $: y = $mousePos.y - 10;
  $: x =
    $mousePos.x - halfWidth < 0
      ? halfWidth
      : $mousePos.x + halfWidth > windowWidth
      ? windowWidth - halfWidth
      : $mousePos.x;
</script>

<svelte:window bind:innerWidth={windowWidth} />

<div
  style={`
    position: absolute; 
    z-index: 99999; 
    top: ${y}px; 
    left: ${x}px; 
    pointer-events:none'`}>
  <div
    className="tooltip"
    style="position:absolute; bottom:10px; padding:10px; background:#000;
    maxWidth:{width}px; box-shadow:rgba(0,0,0, .2);border-radius:4px; 0px 0px
    10px; transform: translate(-50%,0px);">
    <slot />
  </div>
</div>
