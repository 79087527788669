<script>
  export let x = 0;
  export let y = 0;
  export let r = 20;
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  .dot {
    fill: none;
    stroke: $c-blue-gray-15;
  }
</style>

<circle class="dot" cx={x} cy={y} {r} />
