// https://github.com/baptistemanteau/colorharmonies/blob/master/colorharmonies/colorharmonies.py
// http://www.paletton.com/wiki/index.php?title=Split-complementary_color_scheme_(accented)
import {
  forEach,
  map,
  range,
  filter,
  orderBy,
  groupBy,
  flatten,
} from 'lodash-es';

const binCount = 12;
const binDomain = 360;

export const binStep = binDomain / binCount;
export const bins = map(range(binCount), (d) => {
  return Math.round(d * binStep);
});

export const colorSchemes = [
  'Tetradic Color',
  'Split Complentary Color Accented',
  'Analogous Color accented',
  'Dual Colors',
  'Triadic Color',
  'Split Complentary Color',
  'Analogous Color',
  'Complentary Color',
  'Monochromatic Color',
];

// 0 30 60 90 120 150 180 210 240 270 300 330
// 0 1  2  3  4   5   6   7   8   9   10  11

export const colorSchemesExamples = [
  [0, 2, 6, 8],
  [0, 5, 6, 7],
  [0, 11, 1, 6],
  [0, 6, 1, 7],
  [0, 8, 4],
  [0, 5, 7],
  [0, 11, 1],
  [0, 6],
  [0],
];

export const monochromaticColor = (angle) => {
  return [angle];
};

export const analogousColor = (angle, count) => {
  const step = Math.floor(360 / count);
  const angle1 = (360 + angle - step) % 360;
  const angle2 = (360 + angle + step) % 360;
  return [angle1, angle, angle2];
};

export const analogousColorAccented = (angle, count) => {
  const bigStep = Math.floor(360 * 0.5);
  const smallStep = Math.floor(360 / count);
  const angle1 = (360 + angle - smallStep) % 360;
  const angle2 = (360 + angle + smallStep) % 360;
  const angle3 = (360 + angle + bigStep) % 360;
  return [angle1, angle, angle2, angle3];
};

export const complentaryColor = (angle) => {
  const step = Math.floor(360 * 0.5);
  const angle1 = (360 + angle + step) % 360;
  return [angle, angle1];
};

export const splitComplentaryColor = (angle, count) => {
  const bigStep = Math.floor(360 * 0.5);
  const smallStep = 360 / count;
  const baseAngle = 360 + angle + bigStep;
  const angle1 = (baseAngle - smallStep) % 360;
  const angle2 = (baseAngle + smallStep) % 360;
  return [angle, angle1, angle2];
};

export const splitComplentaryColorAccented = (angle, count) => {
  const bigStep = Math.floor(360 * 0.5);
  const smallStep = 360 / count;
  const baseAngle = 360 + angle + bigStep;
  const angle1 = (baseAngle - smallStep) % 360;
  const angle2 = baseAngle % 360;
  const angle3 = (baseAngle + smallStep) % 360;
  return [angle, angle1, angle2, angle3];
};

export const triadicColor = (angle, count) => {
  const step = Math.floor(360 / 3);
  const angle1 = (360 + angle - step) % 360;
  const angle2 = (360 + angle + step) % 360;
  return [angle1, angle, angle2];
};

export const tetradicColor = (angle, count) => {
  const bigStep = Math.floor(360 / 2);
  const smallStep = Math.floor(360 / count) * 2;
  const angle1 = (360 + angle + smallStep) % 360;
  const angle2 = (360 + angle + bigStep) % 360;
  const angle3 = (360 + angle + bigStep + smallStep) % 360;
  return [angle, angle1, angle2, angle3];
};

export const createGroupedStats = (arr, getValue) => {
  return map(
    filter(
      map(
        groupBy(
          filter(
            map(arr, (d, i) => ({
              value: getValue(d),
              index: i,
            })),
            (d) => d.value
          ),
          'value'
        ),
        (d, k) => {
          return {
            id: k,
            positions: map(d, (e) => e.index),
          };
        }
      ),
      (d) => d.positions.length > 1
    ),
    (d) => {
      const pos = [];
      let currentGroup = [];
      let lastIndex = null;
      forEach(d.positions, (p) => {
        if (lastIndex + 1 !== p) {
          if (currentGroup.length) {
            pos.push(currentGroup);
            currentGroup = [];
          }
        }
        currentGroup.push(p);
        lastIndex = p;
      });
      if (currentGroup.length) {
        pos.push(currentGroup);
        currentGroup = [];
      }
      return { ...d, positions: pos };
    }
  );
};

export const createStacks = (group) => {
  return filter(
    flatten(
      map(group, (d) =>
        map(d.positions, (e, i) => ({
          id: d.id + '-' + i,
          groupId: d.id,
          positions: e[0] !== e[e.length - 1] ? [e[0], e[e.length - 1]] : [],
          // positions: e
        }))
      )
    ),
    (d) => d.positions.length > 1
  );
};

export const createConnections = (group) => {
  return orderBy(
    map(
      filter(group, (d) => d.positions.length > 1),
      (d) => {
        const connections = [];
        for (let i = 1; i < d.positions.length; i++) {
          const x1 = d.positions[i - 1][d.positions[i - 1].length - 1];
          const x2 = d.positions[i][0];
          connections.push({
            id: d.id + '-' + i,
            groupId: d.id,
            positions: [x1, x2],
          });
        }
        return connections;
      }
    ),
    (d) => d.length,
    'desc'
  );
};

const toHex = (x) => {
  const hex = x.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
};

export const hslToRGB = function (h, s, l) {
  h /= 360;
  s /= 100;
  l /= 100;
  let r, g, b;
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255),
  };
};

export const rgbToHex = function (r, g, b) {
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

export const luminanace = function (r, g, b) {
  var a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

export const contrast = function (rgb1, rgb2) {
  return (
    (luminanace(rgb1.r, rgb1.g, rgb1.b) + 0.05) /
    (luminanace(rgb2.r, rgb2.g, rgb2.b) + 0.05)
  );
};
