<script>
  import { forEach, range, map } from "lodash-es";
  import { polarToCartesian } from "../../utils/geom-2d";
  import { scaleSqrt } from "d3-scale";

  export let x = 0;
  export let y = 0;
  export let colors = [];
  export let width;
  export let height;

  const sliceCount = 12;
  const sliceWidth = (Math.PI * 2) / sliceCount;

  $: size = Math.min(width, height);
  $: radius = size / 2;
  $: pieRadius = radius - 2;

  $: centerX = 0;
  $: centerY = 0;

  $: colorSlices = map(colors, (d, i) => {
    const angle = d * sliceWidth - Math.PI / 2 - Math.PI / 12;
    const startAngle = angle;
    const endAngle = angle + sliceWidth;
    const largeArcFlag = endAngle - startAngle <= Math.PI ? "0" : "1";
    const color = `hsl(${(d / sliceCount) * 360},50%,50%)`;
    const start = polarToCartesian(centerX, centerY, pieRadius, startAngle);
    const end = polarToCartesian(centerX, centerY, pieRadius, endAngle);
    return {
      color: color,
      path: [
        "M",
        centerX,
        centerY,
        "L",
        start.x,
        start.y,
        "A",
        radius,
        radius,
        0,
        largeArcFlag,
        1,
        end.x,
        end.y,
        "L",
        centerX,
        centerY
      ].join(" ")
    };
  });
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  circle {
    fill: $c-blue-gray-0;
    stroke: $c-blue-gray-4;
  }
  path {
    fill: $c-white;
    stroke: $c-blue-gray-0;
    stroke-width: 0.25px;
  }
</style>

<g class="color-scheme" transform="translate({x},{y})">
  <circle r={radius} />
  {#each colorSlices as d}
    <path d={d.path} />
  {/each}
</g>
