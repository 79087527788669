<script>
  import { createEventDispatcher } from 'svelte';
  import CloseButton from './CloseButton.svelte';

  const dispatch = createEventDispatcher();

  function handleKeydown(event) {
    if (event.keyCode === 27) {
      dispatch('close');
    }
  }
</script>

<style lang="scss">
  @import './styles/theme.scss';
  .intro-overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.45);
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  .intro-overlay__content {
    pointer-events: none;
    position: absolute;
    width: 90%;
    max-width: 800px;
    left: 50%;
    top: 50%;
    padding: 15px 20px 20px 20px;
    background: #fff;
    transform: translate(-50%, -50%);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
  }
  .intro-overlay__header {
    @include fluid-font-size($fs-semi-medium);
    @include fluid-space(margin-bottom, $sp-xsmall);
    color: $c-blue-gray-1;
  }
  .intro-overlay__close {
    pointer-events: all;
    position: absolute;
    right: 10px;
    top: 15px;
  }
</style>

<svelte:window on:keydown={handleKeydown} />

<div
  class="intro-overlay"
  on:click={() => {
    dispatch('close');
  }}>
  <div class="intro-overlay__content">
    <div class="intro-overlay__close">
      <CloseButton
        tip="Close"
        isBig
        on:click={() => {
          dispatch('close');
        }} />
    </div>
    <div class="intro-overlay__wrapper">
      <div class="intro-overlay__header">Detail view introduction</div>
      <img src="images/moebius-colors-intro.jpg" alt="Ways to interact" />
    </div>
  </div>
</div>
