<script>
  import { forEach, orderBy, filter } from "lodash-es";

  export let colors = [];
  export let width = 5;
  export let height = 30;
  export let x;
  export let y;
  export let total = 1;
  export let mode = "vertical";

  let stackedColors = [];
  $: sortedColors = orderBy(colors, d => d[2], "desc");

  $: {
    if (mode === "vertical") {
      let w = 0;
      stackedColors = [];
      forEach(sortedColors, d => {
        const tmpw = (width * d[3]) / total;
        stackedColors.push({ ...d, y: 0, x: w, width: tmpw, height: height });
        w += tmpw;
      });
    } else if (mode === "horizontal") {
      let h = 0;
      stackedColors = [];
      forEach(sortedColors, d => {
        const tmph = (height * d[3]) / total;
        stackedColors.push({ ...d, x: 0, y: h, height: tmph, width: width });
        h += tmph;
      });
    }
  }
</script>

<style lang="scss">
  @import "./styles/theme.scss";
</style>

<g class="color-palette" transform={`translate(${x},${y})`}>
  {#each stackedColors as d}
    {#if d.height > 0}
      <rect
        x={d.x}
        y={d.y}
        width={d.width}
        height={d.height}
        fill={`hsl(${d[0]},${d[1]}%,${d[2]}%)`} />
    {/if}
  {/each}
</g>
