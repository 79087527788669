<script>
  import Tooltip from "./Tooltip.svelte";
  export let hoveredColor = null;
  export let hoveredColorScheme = null;
  export let hoveredCopyColor = null;
</script>

<style lang="scss">
  @import "./styles/theme.scss";

  .tooltip__header {
    color: $c-blue-gray-9;
    @include fluid-font-size($fs-small);
  }
  .tooltip__content {
    color: $c-blue-gray-4;
    white-space: nowrap;
    @include fluid-font-size($fs-xsmall);
  }
  .tooltip__color {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 5px;
    text-align: center;
  }
</style>

{#if hoveredColor !== null}
  <Tooltip width={200}>
    <div
      class="tooltip__color"
      style={`background-color: ${hoveredColor.bgColor}; color: ${hoveredColor.textColor} `}>
      {hoveredColor.bgColor}
    </div>
    <div class="tooltip__content">Click to copy the color</div>
  </Tooltip>
{/if}

{#if hoveredColorScheme !== null}
  <Tooltip width={200}>
    <div class="tooltip__header">{hoveredColorScheme.name}</div>
    <div class="tooltip__content">Click to selected a color scheme</div>
  </Tooltip>
{/if}

{#if hoveredCopyColor !== null}
  <Tooltip width={200}>
    <div class="tooltip__content">Click to copy all colors</div>
  </Tooltip>
{/if}
