<script>
  import loadImage from 'image-promise';
  import Loading from './Loading.svelte';

  export let images = [];
  export let hasLoaded = false;

  $: {
    loadImage(images)
      .then(function () {
        hasLoaded = true;
      })
      .catch(function (err) {
        console.error('One or more images have failed to load :(');
        hasLoaded = false;
      });
  }
</script>

<style>
  .preloader {
    width: 100%;
  }
</style>

<div class="preloader">
  {#if !hasLoaded}
    <Loading />
  {/if}
</div>
