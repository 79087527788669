<script>
  import { onMount } from 'svelte';
  import { debounce } from 'lodash-es';

  import {
    selectedItem,
    selectedItemId,
    selectedItemImages,
  } from '../state/selectionState.js';

  import Composition from '../components/vis/Composition.svelte';
  import Scrollbar from '../components/ui/scrollbar/Scrollbar.svelte';
  import Zoom from '../components/ui/Zoom.svelte';
  import Preloader from '../components/ui/Preloader.svelte';
  import Sharing from '../components/ui/Sharing.svelte';
  import Tooltips from '../components/ui/Tooltips.svelte';
  import Header from '../components/ui/Header.svelte';
  import IntroOverlay from '../components/ui/IntroOverlay.svelte';
  import MobileDisclaimer from '../components/ui/MobileDisclaimer.svelte';

  let stageEl;
  let height = 0;
  let width = 0;
  let pos = 0;
  let zoom = 0.25;

  let availableWidth;
  let fullWidth;
  let imagesLoaded = false;

  let showIntroOverlay = false;
  let saveIntroCookie = false;

  let hoveredColor = null;
  let hoveredColorScheme = null;
  let hoveredCopyColor = null;

  const slideHeight = 35;
  const slideWidth = 70;

  const posChange = selectedItemId.subscribe(() => {
    // reset vars for new comic
    pos = 0;
    imagesLoaded = false;
  });

  //  add no scroll class
  if (!window.document.body.classList.contains('no-scroll')) {
    window.document.body.classList.add('no-scroll');
  }

  $: compositionHeight = height - slideHeight;
  $: compositionWidth = width - slideWidth;

  $: scrollBarIsEnabled = availableWidth < fullWidth;
  $: scrollbarWidth = scrollBarIsEnabled
    ? (availableWidth * width) / fullWidth
    : width;

  $: {
    // fixes positive pos not enabled scrollbar
    if (!scrollBarIsEnabled && pos > 0) {
      pos = 0;
    }
  }

  $: isMobile = width < 768;

  function getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  onMount(() => {
    setDimensions();
    // check cookies for intro
    if (getCookie('show-intro') === '') {
      showIntroOverlay = true;
      saveIntroCookie = true;
    }
  });

  const onResize = debounce(() => {
    location.reload(false); //hacky
    setDimensions();
  }, 200);

  function setDimensions() {
    height = stageEl.offsetHeight;
    width = stageEl.offsetWidth;
  }

  function onScroll(e) {
    if (!window.chrome) {
      e.preventDefault();
    }

    if (
      scrollBarIsEnabled &&
      Math.abs(e.deltaX) > Math.abs(e.deltaY) &&
      Math.abs(e.deltaX) > 0
    ) {
      pos += e.deltaX * 0.001;

      if (pos < 0) {
        pos = 0;
      } else if (pos > 1) {
        pos = 1;
      }
    } else if (
      Math.abs(e.deltaX) < Math.abs(e.deltaY) &&
      Math.abs(e.deltaY) > 0
    ) {
      zoom += e.deltaY * -0.001;
      if (zoom < 0) {
        zoom = 0;
      } else if (zoom > 1) {
        zoom = 1;
      }
    }
  }
</script>

<style lang="scss">
  @import './styles/theme.scss';
  .comic {
    background-color: $c-blue-gray-0;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .comic__controls {
    margin-bottom: 30px;
    padding: 15px 10px 10px 10px;
    border-bottom: 1px solid #555;
  }
  .comic__stage {
    flex: 1;
  }
  .comic__stage-field {
    display: flex;
    flex-direction: row;
  }
  .comic__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      color: $c-blue-gray-5;
      text-decoration: none;
      &:hover {
        color: $c-white;
      }
    }
    .back-btn {
      margin-right: 5px;
      color: $c-blue-gray-5;
    }

    h1 {
      color: $c-white;
      letter-spacing: 0.5px;
      span {
        color: $c-blue-gray-5;
      }
    }
    &.is-mobile {
      h1 {
        width: 100%;
        text-align: center;
      }
      .back-btn {
        margin-right: 15px;
      }
    }
  }
  .comic__header-links {
    display: flex;
    align-items: center;
  }
  .comic__header-link {
    margin-right: 10px;
    padding: 0 10px;
    color: $c-blue-gray-5;
    cursor: pointer;
    &:hover,
    &.is-active {
      color: $c-blue-gray-9;
    }
  }
</style>

<svelte:window on:resize={onResize} on:wheel={onScroll} />

<div class="comic">
  <Header>
    <div class="comic__header" class:is-mobile={isMobile}>
      <a href="/#comics/">
        <span class="back-btn">&lt;</span>
        {#if !isMobile}Overview{/if}
      </a>
      <h1>{$selectedItem.name} <span>({$selectedItem.year})</span></h1>
      {#if !isMobile}
        <div class="comic__header-links">
          <div
            class="comic__header-link"
            class:is-active={showIntroOverlay}
            on:click={() => {
              showIntroOverlay = !showIntroOverlay;
            }}>
            Intro
          </div>
          <Sharing
            url={`https://moebius.colortheft.com/#comics/${$selectedItemId}`}
            text="The Colors of Jean Giraud, aka #Moebius, is an interactive data visualization that explores the artist's comics and how he used colors over time."
            hashtags="jeangiraud #comics #illustration #colors #datavis" />
        </div>
      {/if}
    </div>
  </Header>
  <div class="comic__stage" bind:this={stageEl}>
    {#if isMobile}
      <MobileDisclaimer />
    {:else if compositionHeight > 0}
      <div class="comic__stage-field">
        <Zoom
          height={compositionHeight}
          width={slideWidth}
          bind:position={zoom} />
        {#if imagesLoaded}
          <Composition
            data={$selectedItem}
            height={compositionHeight}
            width={compositionWidth}
            bind:availableWidth
            bind:fullWidth
            bind:hoveredColor
            bind:hoveredColorScheme
            bind:hoveredCopyColor
            {pos}
            {zoom} />
        {:else}
          <Preloader
            images={$selectedItemImages}
            bind:hasLoaded={imagesLoaded} />
        {/if}
      </div>
      <Scrollbar
        {width}
        height={slideHeight}
        barWidth={scrollbarWidth}
        isDeactivated={!scrollBarIsEnabled}
        bind:position={pos} />
    {/if}
  </div>
  {#if showIntroOverlay}
    <IntroOverlay
      on:close={() => {
        if (showIntroOverlay) {
          if (saveIntroCookie) {
            setCookie('show-intro', true, 360);
          }
        }
        showIntroOverlay = !showIntroOverlay;
      }} />
  {/if}
  <Tooltips {hoveredColor} {hoveredColorScheme} {hoveredCopyColor} />
</div>
