<script>
  import { forEach, range, map } from "lodash-es";
  import { polarToCartesian } from "../../utils/geom-2d";
  import { scaleSqrt } from "d3-scale";

  export let x = 0;
  export let y = 0;
  export let colors = [];
  export let width;
  export let height;

  const sliceCount = 12;
  const sliceWidth = (Math.PI * 2) / sliceCount;
  const slideAngleWidth = 360 / sliceCount;

  $: size = Math.min(width, height);
  $: radius = size / 2;
  $: pieRadius = radius - 2;

  $: centerX = 0;
  $: centerY = 0;

  $: colorSlices = map(colors, (d, i) => {
    const index = d.bin / slideAngleWidth;

    const angle = index * sliceWidth - Math.PI / 2 - Math.PI / 12;
    const startAngle = angle;
    const endAngle = angle + sliceWidth;

    const largeArcFlag = endAngle - startAngle <= Math.PI ? "0" : "1";
    const color = `hsl(${d.color[0]},${d.color[1]}%,${d.color[2]}%)`;
    const strokeColor = `hsl(${d.bin},50%,50%)`;

    const start = polarToCartesian(centerX, centerY, pieRadius, startAngle);
    const end = polarToCartesian(centerX, centerY, pieRadius, endAngle);
    return {
      id: d.bin,
      color: color,
      strokeColor: strokeColor,
      opacity: Math.min(d.value * 3, 1),
      path: [
        "M",
        centerX,
        centerY,
        "L",
        start.x,
        start.y,
        "A",
        radius,
        radius,
        0,
        largeArcFlag,
        1,
        end.x,
        end.y,
        "L",
        centerX,
        centerY
      ].join(" ")
    };
  });
</script>

<style lang="scss">
  @import "./styles/theme.scss";
  circle {
    fill: $c-blue-gray-0;
    stroke: $c-blue-gray-4;
  }
</style>

<g class="color-circle" transform="translate({x},{y})">
  <circle stroke="#444" r={radius} fill="none" />
  {#each colorSlices as d (d.id)}
    <g>
      <path d={d.path} fill={d.color} fill-opacity={d.opacity} stroke="none" />
      <path
        d={d.path}
        fill="none"
        stroke={d.strokeColor}
        stroke-width="0.5"
        stroke-opacity="0.5" />
    </g>
  {/each}
</g>
