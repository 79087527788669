<script>
  import { forEach, map, sumBy } from "lodash-es";
  import {
    stack as d3stack,
    area as d3area,
    stackOrderNone,
    stackOffsetNone
  } from "d3-shape";
  import { scaleLinear } from "d3-scale";

  import PageColorPalette from "./PageColorPalette.svelte";

  const margins = { top: 0, right: 0, bottom: 0, left: 0 };

  export let elements = [];
  export let bins = [];
  export let width = 500;
  export let height = 300;
  export let offset = 0;
  export let stageOffset = 0;
  export let stripeWidth = 0;
  export let stripeSpace = 0;
  export let stripeOpacity = 0;
  export let stripeBuffer = 0;
  export let selectedColorScheme = null;
  export let hoveredColorScheme = null;
  export let showsPages = false;

  $: stripeSpaceHalf = stripeSpace / 2;
  $: stripeWidthHalf = stripeWidth / 2;

  let series = [];

  $: stageWidth = width - margins.left - margins.right;
  $: stageHeight = height - margins.top - margins.top;

  $: {
    const stack = d3stack()
      .keys(bins)
      .value((d, key) => {
        return d[key].value;
      })
      .order(stackOrderNone)
      .offset(stackOffsetNone);

    series = stack(elements);
  }

  $: streams = map(series, (pages, i) => {
    const stream = { id: bins[i], colors: [], connections: [] };
    let py1 = 0;
    let py2 = 0;
    let pValue = 0;
    let pColors = [];
    let pAvgColor = {};
    let pX = 0;

    forEach(pages, (page, pageIndex) => {
      const bin = page.data[bins[i]];

      const y1 = (page[0] * height) / page.data.sum;
      const y2 = (page[1] * height) / page.data.sum;
      const x = page.data.x;
      const offsetX = x + stageOffset;
      // console.log(x, offset, width, "|", x < offset, x > width);

      const isVisible = !(
        offsetX < offset - stripeBuffer || offsetX > width + stripeBuffer
      );

      if (bin.value > 0) {
        stream.colors.push({
          id: bin.id,
          x,
          y: y1 + 1,
          width: stripeWidth,
          height: y2 - y1 - 1,
          ...bin,
          value: sumBy(bin.colors, c => c[3]),
          colorScheme: page.data.colorSchemeId,
          isVisible
        });
      }

      if (pageIndex > 0 && (bin.value > 0 && pValue > 0)) {
        const x1 = x - stripeSpace - stripeWidthHalf;
        const x2 = pX + stripeSpace + stripeWidthHalf;
        const oy1 = y1 + stripeSpaceHalf;
        const oy2 = y2 - stripeSpaceHalf;
        const y3 = py1 + stripeSpaceHalf;
        const y4 = py2 - stripeSpaceHalf;

        const ym1 = oy1 === oy2 ? oy1 : Math.abs(oy2 - oy1) / 2;
        const ym2 = y3 === y4 ? y3 : Math.abs(y4 - y3) / 2;
        const angle = Math.atan2(ym2 - ym1, x2 - x1);

        stream.connections.push({
          id: bin.id,
          x1,
          x2,
          y1: oy1,
          y2: oy2,
          y3,
          y4,
          color1: bin.avgColor,
          color2: pAvgColor,
          angle,
          colorScheme:
            page.data.colorSchemeId === pages[pageIndex - 1].data.colorSchemeId
              ? page.data.colorSchemeId
              : null,
          isDimmed: !showsPages && page.data.isDimmed,
          isVisible
          // value: Math.min(value * 1.5, 0.7)
        });
      }
      pX = x;
      py1 = y1;
      py2 = y2;
      pValue = bin.value;
      pColors = bin.colors;
      pAvgColor = bin.avgColor;
    });
    return stream;
  });
</script>

<style lang="scss">
  @import "./styles/theme.scss";
</style>

<g transform={`translate(${stageOffset},${0})`}>
  <defs>
    {#each streams as { id, colors, connections } (id)}
      {#each connections as { color1, color2, angle, id } (id)}
        <linearGradient gradientTransform="rotate({angle})" id="gradient-{id}">
          <stop
            offset="0%"
            stop-color={`hsl(${color2[0]},${color2[1]}%,${color2[2]}%)`} />
          <stop
            offset="100%"
            stop-color={`hsl(${color1[0]},${color1[1]}%,${color1[2]}%)`} />
        </linearGradient>
      {/each}
    {/each}
  </defs>

  {#each streams as { id, colors, connections } (id)}
    {#each connections as { x1, x2, y1, y2, y3, y4, color1, id, colorScheme, isDimmed, isVisible } (id)}
      {#if isVisible}
        <path
          {id}
          d={`M ${x2},${y3} L ${x1},${y1} L ${x1},${y2} L ${x2},${y4} Z`}
          fill={`url(#gradient-${id})`}
          opacity={(selectedColorScheme === null && hoveredColorScheme === null) || (colorScheme !== null && ((selectedColorScheme !== null && selectedColorScheme.id === colorScheme) || (hoveredColorScheme !== null && hoveredColorScheme.id === colorScheme))) ? (!isDimmed ? stripeOpacity : stripeOpacity * 0.5) : 0.2} />
      {/if}
    {/each}
    {#each colors as c}
      {#if c.isVisible}
        <g
          opacity={(selectedColorScheme === null && hoveredColorScheme === null) || (c.colorScheme !== null && ((selectedColorScheme !== null && selectedColorScheme.id === c.colorScheme) || (hoveredColorScheme !== null && hoveredColorScheme.id === c.colorScheme))) ? 1 : 0.3}>
          <PageColorPalette
            colors={c.colors}
            x={c.x - stripeWidthHalf}
            y={c.y}
            total={c.value}
            width={c.width}
            height={c.height}
            mode="horizontal" />
        </g>
      {/if}
    {/each}
  {/each}
</g>
