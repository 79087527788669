import { writable, derived } from 'svelte/store';
import { comics } from '../data/dataAPI.js';
import { isEmpty, map } from 'lodash-es';

export const selectedItemId = writable();

export const selectedItem = derived(
  [selectedItemId, comics],
  ([$selectedItemId, $comics]) =>
    $comics.filter((x) => x.id === $selectedItemId)[0]
);

export const selectedItemImages = derived([selectedItem], ([$selectedItem]) => {
  if (isEmpty($selectedItem)) {
    return [];
  }
  return map($selectedItem.pages, (d) => 'comics/' + d.file);
});

export const hasSelection = derived(
  [selectedItem],
  ([$selectedItem]) => $selectedItem != null
);
