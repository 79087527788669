import { writable, derived } from 'svelte/store';

const defaultView = 'index';

export const viewOptions = [
  { id: 'comics', label: 'index' },
  { id: 'comic', label: '' },
];

export const viewId = (() => {
  const _viewId = writable(viewOptions[0].id);
  const { subscribe, set, update } = _viewId;
  return {
    subscribe,
    set: (value) => {
      const m = viewOptions.filter((x) => x.id == value)[0] || viewOptions[0];
      set(m.id);
    },
    update,
  };
})();

export const viewStore = derived([viewId], ([_viewId], set) => {
  const m = viewOptions.filter((x) => x.id == _viewId)[0] || viewOptions[0];
  set(m);
  viewId.set(m.id);
});
